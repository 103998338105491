<template>
	<div v-if="isLayers" class="notificationlayerslist d-flex flex-column pa-0" style="width: 100%">
		<div class="mt-1 subheader">
			<!-- Selection controls: All & Nothing -->
			<span class="selector" @click="changeAll(true)">{{ $t('panels.configpannel.all') }}</span>
			<span class="ml-2 mr-2">|</span>
			<span class="selector" @click="changeAll(false)">{{ $t('panels.configpannel.none') }}</span>
		</div>

		<div v-if="!isSuperAdmin">
			<!-- Layer List -->
			<v-list dense style="background: transparent !important" class="layer-list py-0">
				<div v-if="isLayers">
					<div v-for="(layer, i) in layers" :key="i">
						<layer-list-tooltip
							v-if="layer.hasNotifications"
							:value="true"
							:active="isActive(layer.identifier)"
							@update:active="(value) => setActive(layer.identifier, value)"
							:title="layer.title"
							:disabledItem="layer.disabledItem"
							:disabledDataSource="layer.datasourcetypedisabled"
							:tooltipTitle="getLayerTooltip(layer.description)"
							:noPadding="true"
							border-bottom
						>
						</layer-list-tooltip>
					</div>
				</div>
				<div v-else class="d-flex justify-center">
					<div class="switchoff d-flex flex-column align-center justify-center px-0 py-2" style="width: 100%">
						<v-icon size="1.5rem"> fal fa-inbox </v-icon>
						<div class="switchoff-text">{{ $t('common.noLayerData') }}</div>
					</div>
				</div>
			</v-list>
		</div>
		<div v-else>
			<!-- Layer List by Organization -->
			<div v-for="(item, i) in layersByOrg" :key="i">
				<v-list flat dense style="background: transparent" class="pt-0">
					<v-list-group>
						<template #activator>
							<v-list-item-title class="high fw-bold fs-regular ml-n2">{{ item.organizationname }}</v-list-item-title>
						</template>

						<div v-for="(layer, i) in item.layerGroup" :key="i">
							<layer-list-tooltip
								v-if="layer.hasNotifications"
								:value="true"
								:active="isActive(layer.identifier)"
								@update:active="(value) => setActive(layer.identifier, value)"
								:title="layer.title"
								:disabledItem="layer.disabledItem"
								:disabledDataSource="layer.datasourcetypedisabled"
								:tooltipTitle="getLayerTooltip(layer.description)"
								:noPadding="true"
								border-bottom
							>
							</layer-list-tooltip>
						</div>
					</v-list-group>
				</v-list>
			</div>
		</div>
	</div>
	<div v-else class="width-100 d-flex justify-center">
		<div class="switchoff d-flex flex-column align-center justify-center px-0 py-2" style="width: 100%">
			<v-icon size="1.5rem"> fal fa-inbox </v-icon>
			<div class="switchoff-text">{{ $t('common.noLayerData') }}</div>
		</div>
	</div>
</template>

<script>
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
//import LayerListTitle from './subpanels/common/LayerListTitle';
import LayerListTooltip from './subpanels/common/LayerListTooltip';

import constants from '@/utils/constants';
import { isSuperAdmin } from '@/api/common';

export default {
	name: 'NotificationLayersList',

	data() {
		return {
			allLayers: [],
			//satelliteImagesGroupedByType: [],
			layersByOrg: [],
			profile: '',
			isSuperAdmin: false
		};
	},

	components: {
		//LayerListTitle,
		LayerListTooltip
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		console.log('SUPER_ADMIN_ROLE?: ' + this.isSuperAdmin);
		if (this.isSuperAdmin) {
			this.afterGetData();
		}
		console.log('== Layers getter ==', this.$store.getters.layers, Object.values(this.$store.getters.layers), this.layers);
	},
	computed: {
		isLayers() {
			console.log('debug 1122', Object.keys(this.layers).length > 0);
			return Object.keys(this.layers).length > 0;
		},

		layers() {
			return Object.assign(
				{},
				Object.values(this.$store.getters.layers).filter((layer) => layer.model == constants.NOTIFICATIONS_MODEL)
			);
			//return this.$store.getters.layers;
		}
	},
	methods: {
		isActive(layerId) {
			return this.$store.state.mappanel.layers[layerId]['active'];
		},
		changeAll(value) {
			console.log('debug change all', this.layers, Object.values(this.layers), this.$store.state.mappanel.layers);
			Object.values(this.layers).forEach((layer) => this.toggleAll(layer.identifier, value));

			if (!value) {
				this.$puiNotify.info(this.$t('msg.nolayers'));
			}
		},

		toggleAll(layer, value) {
			ol2map.setVisibility(layer, value);
			this.$store.state.mappanel.layers[layer]['active'] = value;
		},

		setActive(layer, active) {
			ol2map.setVisibility(layer, active);
			this.$store.state.mappanel.layers[layer]['active'] = active;
		},
		async afterGetData() {
			this.allLayers = Object.entries(this.layers).map(([name, obj]) => ({ name, ...obj }));
			console.log('-- Layers retrieved --', this.allLayers, this.layers);
			this.layersByOrg = this.groupByOrganization(this.allLayers);
			console.log('Layers grouped by organization', this.layersByOrg);
		},

		groupByOrganization(dataset) {
			console.log('DATASET LAYERS');
			console.log(dataset);
			const object = dataset.reduce((r, { organizationname, ...rest }) => {
				if (!r[organizationname]) r[organizationname] = { organizationname, layerGroup: [rest] };
				else r[organizationname].layerGroup.push(rest);
				return r;
			}, {});

			const result = Object.values(object);
			return result;
		},
		getLayerTooltip(description) {
			console.log('layer tooltip ' + description, this.$store.getters.getParameters);
			if (description && description.trim() != '') {
				return description;
			}
			return null;
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';
.notificationlayerslist {
	& .subheader {
		font-size: 10px;
		color: var(--azul-primary-100);

		& .selector {
			color: var(--azul-primary-100);
			font-size: 0.9rem;
			line-height: 1.33;

			&:hover {
				cursor: pointer;
				font-weight: 600;
			}
		}
	}
	& .switchoff {
		color: var(--gris-300);
		& .switchoff_icon {
			font-size: 2rem;
		}
		& .switchoff-text {
			font-size: 1rem;
		}
	}
	& .bottomborder {
		border-bottom: 1px solid var(--moderate);
	}
}
</style>
