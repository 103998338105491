<template>
	<div class="layerslist d-flex flex-column pa-0 pb-2" style="width: 100%">
		<div v-if="!isSuperAdmin">
			<v-row justify="center">
				<v-date-picker
					v-model="dateSelected"
					first-day-of-week="1"
					:show-adjacent-months="false"
					:color="primaryColor"
					class="ma-4"
					:min="firstDateAvailable"
					:max="lastDateAvailable"
					:allowed-dates="allowedDates"
					:locale="userLanguage"
					full-width
					no-title
					@input="getGeoJsonImage()"
				></v-date-picker>
			</v-row>
			<!-- <v-layout wrap class="px-1">
					<v-flex xs12 md12>
						<pui-date-field
							:label="$t('rasterTools.date')"
							toplabel
							v-model="dateSelected"
							:disabled="formDisabled"
							:min="firstDateAvailable"
							:max="lastDateAvailable"
							:tooltipDescription="$t('rasterTools.dateTooltip')"
							@input="getGeoJsonImage()"
						></pui-date-field>
					</v-flex>
				</v-layout> -->
			<v-divider v-if="selectedImage" class="mt-2"></v-divider>
			<v-list v-if="selectedImage" flat dense style="background: transparent" class="pt-0">
				<div v-for="(pollutant, k) in selectedImage.pollutants" :key="k">
					<v-list dense style="background: transparent !important" class="layer-list py-0">
						<v-list-group>
							<template #activator>
								<v-list-item-title class="high fw-bold fs-regular ml-2" :title="getParameterName(pollutant.parameter)">{{
									getParameterName(pollutant.parameter)
								}}</v-list-item-title>
							</template>
							<div v-for="(image, k) in pollutant.images" :key="k">
								<layer-list-tooltip
									:value="true"
									:active="isActive(image.identifier)"
									@update:active="(value) => setActive(image.identifier, value)"
									:title="image.title"
									:date="image.date.split('T')[0]"
									:tooltipTitle="getParameterTooltip(pollutant.parameter)"
									:noPadding="true"
									border-bottom
								>
								</layer-list-tooltip>
							</div>
						</v-list-group>
					</v-list>
				</div>
				<!-- </v-list-group> -->
			</v-list>
		</div>
		<div v-else>
			<!-- Layer List by Organization-->
			<div v-for="(item, i) in geojsonGroupedByOrg" :key="i">
				<v-list flat dense style="background: transparent" class="pt-0">
					<v-list-group>
						<template #activator>
							<v-list-item-title class="high fw-bold fs-regular ml-n4">{{ item.organizationname }}</v-list-item-title>
						</template>
						<v-row justify="center" class="pb-4">
							<v-date-picker
								v-model="dateSelected"
								first-day-of-week="1"
								:show-adjacent-months="false"
								:color="primaryColor"
								class="mt-4"
								:min="firstDateAvailable"
								:max="lastDateAvailable"
								:allowed-dates="allowedDates"
								:locale="userLanguage"
								full-width
								no-title
								@input="getGeoJsonImage(i, item.images)"
							></v-date-picker>
						</v-row>
						<!-- <v-layout wrap class="px-1">
								<v-flex xs12 md12>
									<pui-date-field
										:label="$t('rasterTools.date')"
										toplabel
										v-model="dateSelected"
										:disabled="formDisabled"
										:min="firstDateAvailable"
										:max="lastDateAvailable"
										:tooltipDescription="$t('rasterTools.dateTooltip')"
										@input="getGeoJsonImage(i, item.images)"
									></pui-date-field>
								</v-flex>
							</v-layout> -->
						<div v-if="selectedImage[i]">
							<div v-for="(pollutant, k) in selectedImage[i].pollutants" :key="k">
								<v-list dense style="background: transparent !important" class="layer-list py-0">
									<v-list-group>
										<template #activator>
											<v-list-item-title class="high fw-bold fs-regular ml-2" :title="getParameterName(pollutant.parameter)">{{
												getParameterName(pollutant.parameter)
											}}</v-list-item-title>
										</template>
										<div v-for="(image, k) in pollutant.images" :key="k">
											<layer-list-tooltip
												:value="true"
												:active="isActive(image.identifier)"
												@update:active="(value) => setActive(image.identifier, value)"
												:title="image.title"
												:date="selectedImage[i].date.split('T')[0]"
												:tooltipTitle="getParameterTooltip(pollutant.parameter)"
												:noPadding="true"
												border-bottom
											>
											</layer-list-tooltip>
										</div>
									</v-list-group>
								</v-list>
								<!-- <v-list dense style="background: transparent !important" class="layer-list py-0">
										<layer-list-tooltip
											:value="true"
											:active="isActive(pollutant.url)"
											@update:active="(value) => setActive(pollutant.url, value)"
											:title="pollutant.pollutant"
											:tooltipTitle="getParameterTooltip(pollutant.pollutant)"
											:organization="pollutant.organizationacronym"
											:date="selectedImage[i].date.split('T')[0]"
											:noPadding="true"
											border-bottom
										>
										</layer-list-tooltip>
									</v-list> -->
							</div>
						</div>
					</v-list-group>
				</v-list>
			</div>
		</div>
	</div>
</template>

<script>
import LayerListTooltip from './subpanels/common/LayerListTooltip.vue';

import VIZ_DEFINITIONS from '@/components/map/layers';
import { RASTER_LAYER_PROPERTIES } from '@/lib/constants/layers';
import { search, filterDependOnRole, isSuperAdmin } from '../../../../../api/common';
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';

export default {
	name: 'GeoJsonLayersSection',

	data() {
		return {
			allGeoJsonImages: [],
			geojsonGroupedByOrg: [],
			isSuperAdmin: false,
			profile: '',
			dateSelected: null,
			oldDateSelected: null,
			formDisabled: false,
			selectedImage: null,
			firstDateAvailable: null,
			lastDateAvailable: Date.now(),
			allowedDatesArray: [],
			primaryColor: this.$store.state.global.primaryColor
		};
	},

	components: {
		//LayerListTitle,
		LayerListTooltip
	},
	computed: {
		isActive() {
			return (layerId) =>
				this.$store.state.mappanel.geoJsonLayers[layerId] ? this.$store.state.mappanel.geoJsonLayers[layerId].active : false;
			//return (layerId) => this.$store.state.mappanel.geoJsonLayers.find((layer) => layer.identifier === layerId).active ?? false;
			// TODO: Fix issue with geojsonlayers store object structure.
			/* return (layerId) =>
				this.$store.state.mappanel.geoJsonLayers.find((layer) => (layer.pmgeojsonimagesid = layerId))
					? this.$store.state.mappanel.geoJsonLayers[layerId]['active']
					: false; */
		},
		activeGeoJsonLayer: {
			get() {
				return this.$store.getters.getActiveGeoJsonLayer;
			},
			set(value) {
				this.$store.commit('setActiveGeoJsonLayer', value);
			}
		},
		geoJsonLayers() {
			return this.$store.getters.getGeoJsonLayers;
		},
		geoJsonOpacity: {
			get() {
				return this.$store.state.mappanel.geoJsonOpacity;
			},

			set(value) {
				this.$store.commit('setGeoJsonOpacity', value);
			}
		},
		isGeoJsonActive: {
			get() {
				return this.$store.getters.isGeoJsonActive;
			},
			set(value) {
				this.$store.commit('setIsGeoJsonActive', value);
			}
		},
		userLanguage() {
			return this.userProperties.language;
		}
	},
	watch: {
		activeGeoJsonLayer: {
			handler(newValue, oldValue) {
				if (newValue) {
					this.$store.state.mappanel.geoJsonLayers[newValue]['active'] = true;
					console.log('active geojson watcher from', oldValue, ' to ', newValue);
					console.log(
						'active geojson watcher from',
						oldValue ? this.geoJsonLayers[oldValue] : 'no old value',
						' to ',
						this.geoJsonLayers[newValue]
					);
					this.getGeoJsonPolygons(this.geoJsonLayers[newValue]);
				}

				if (oldValue) {
					this.$store.state.mappanel.geoJsonLayers[oldValue]['active'] = false;
					ol2map.setVisibility(oldValue, false);
					ol2map.setOpacity(oldValue, this.geoJsonOpacity * 0.01);
				}
				console.log('active geojson watcher', this.isActive(oldValue), this.isActive(newValue));
				/* this.rasterLayers[oldValue]['active'] = false; */
			}
		}
	},
	beforeCreate() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		this.selectedImage = this.isSuperAdmin ? [] : null;
		if (!this.isSuperAdmin) {
			this.afterGetData();
		} else {
			this.afterGetData_SUPERADMIN();
		}
	},
	mounted() {
		if (this.allGeoJsonImages.length > 0) {
			const uniqueDatesSet = new Set(this.allGeoJsonImages.map((obj) => obj.date.split('T')[0]));
			console.log('debug dates', uniqueDatesSet);
			this.allowedDatesArray = Array.from(uniqueDatesSet);
		}
	},

	methods: {
		setActive(layer, active) {
			console.log('geojson setActive', layer, active, this.$store.state.mappanel.geoJsonLayers[this.activeGeoJsonLayer]);
			console.log('setActive refs', document.getElementById('popup-element'));
			if (this.isGeoJsonActive && document.getElementById('popup-element')) {
				document.getElementById('popup-element').setAttribute('hidden', true);
			}
			console.log(
				'active test',
				Object.values(this.$store.state.mappanel.geoJsonLayers).filter((layer) => layer.active == true)
			);
			// Desactivar la capa raster si hay alguna activa
			/* DEPRECATED - FAULTY
			Object.keys(this.$store.state.mappanel.rasterLayersSettings).forEach((layerId) => {
				if (layerId != layer && this.$store.state.mappanel.rasterLayersSettings[layerId]['active']) {
					this.$store.state.mappanel.rasterLayersSettings[layerId]['active'] = false;
					ol2map.setVisibility(layerId, false);
					ol2map.setOpacity(layerId, this.rasterOpacity * 0.01);
				}
			}); */

			this.$store.state.mappanel.geoJsonLayers[layer]['active'] = active;
			if (active) {
				this.activeGeoJsonLayer = layer;
			} else if (!active && layer == this.activeGeoJsonLayer) {
				this.activeGeoJsonLayer = null;
			}
			console.warn('geojson setActive getter', this.activeGeoJsonLayer, active, layer);
			ol2map.setVisibility(layer, active);
			ol2map.setOpacity(layer, this.$store.state.mappanel.rasterOpacity * 0.01);
		},

		async afterGetData_SUPERADMIN() {
			if (!this.$store.getters.getGeoJsonLayersData) {
				//Filtro por organización
				let filterByOrganization = filterDependOnRole(this.session);

				const body = {
					model: 'pmgeojsonimage',
					filter: filterByOrganization,
					rows: 9999 // Para obtener todos los elementos
				};

				const { data } = await search(body);
				this.allGeoJsonImages = data.data;
				this.$store.commit('setGeoJsonLayersData', this.allGeoJsonImages);
			} else {
				this.allGeoJsonImages = this.$store.getters.getGeoJsonLayersData;
			}
			this.stateGeoJsonImages = this.createGeoJsonListState(this.allGeoJsonImages);

			console.log('-- debug GeoJSON Images retrieved --', this.allGeoJsonImages, this.stateGeoJsonImages);

			this.geojsonGroupedByOrg = this.groupByOrganization(this.stateGeoJsonImages);
			console.log('GeoJSON Images grouped by organization', this.geojsonGroupedByOrg);

			for (const item of this.geojsonGroupedByOrg) {
				console.log('item by org', item);
				item.images = this.groupByDate(item.images);

				// Find the object with the lowest date
				const lowestDateObject = item.images.reduce((prev, current) => {
					const prevDate = new Date(prev.date);
					const currentDate = new Date(current.date);
					return prevDate < currentDate ? prev : current;
				});

				// Find the object with the highest date
				const highestDateObject = item.images.reduce((prev, current) => {
					const prevDate = new Date(prev.date);
					const currentDate = new Date(current.date);
					return prevDate > currentDate ? prev : current;
				});

				console.log('lowest date', lowestDateObject);
				console.log('highest date', highestDateObject);

				this.firstDateAvailable = lowestDateObject.date;
				this.lastDateAvailable = highestDateObject.date;
			}

			this.geojsonGroupedByDate = this.groupByDate(this.allGeoJsonImages);
			console.log('GeoJSON images grouped by date', this.geojsonGroupedByDate);
			console.log('GeoJSON images grouped by pollutant', this.groupByPollutant(this.geojsonGroupedByDate));

			// Find the object with the lowest date
			const lowestDateObject = this.geojsonGroupedByDate.reduce((prev, current) => {
				const prevDate = new Date(prev.date);
				const currentDate = new Date(current.date);
				return prevDate < currentDate ? prev : current;
			});

			// Find the object with the highest date
			const highestDateObject = this.geojsonGroupedByDate.reduce((prev, current) => {
				const prevDate = new Date(prev.date);
				const currentDate = new Date(current.date);
				return prevDate > currentDate ? prev : current;
			});

			console.log('lowest date', lowestDateObject);
			console.log('highest date', highestDateObject);

			this.firstDateAvailable = lowestDateObject.date;
			this.lastDateAvailable = highestDateObject.date;
		},

		async afterGetData() {
			if (!this.$store.getters.getGeoJsonLayersData) {
				//Filtro por organización
				let filterByOrganization = filterDependOnRole(this.session);

				const body = {
					model: 'pmgeojsonimage',
					filter: filterByOrganization,
					rows: 9999 // Para obtener todos los elementos
				};

				const { data } = await search(body);
				this.allGeoJsonImages = data.data;
				this.$store.commit('setGeoJsonLayersData', this.allGeoJsonImages);
			} else {
				this.allGeoJsonImages = this.$store.getters.getGeoJsonLayersData;
			}
			this.createGeoJsonListState(this.allGeoJsonImages);

			console.log('-- GeoJSON Images retrieved --', this.allGeoJsonImages);

			/* // EXTRACT UNIQUE ALLOWED DATES
			const uniqueDatesSet = new Set(this.allGeoJsonImages.map((obj) => obj.date.split('T')[0]));
			const arrayOfUniqueDates = Array.from(uniqueDatesSet); */

			this.geojsonGroupedByDate = this.groupByDate(this.allGeoJsonImages);
			console.log('GeoJSON images grouped by date', this.geojsonGroupedByDate);
			console.log('GeoJSON images grouped by pollutant', this.groupByPollutant(this.geojsonGroupedByDate));

			// Find the object with the lowest date
			const lowestDateObject = this.geojsonGroupedByDate.reduce((prev, current) => {
				const prevDate = new Date(prev.date);
				const currentDate = new Date(current.date);
				return prevDate < currentDate ? prev : current;
			});

			// Find the object with the highest date
			const highestDateObject = this.geojsonGroupedByDate.reduce((prev, current) => {
				const prevDate = new Date(prev.date);
				const currentDate = new Date(current.date);
				return prevDate > currentDate ? prev : current;
			});

			console.log('lowest date', lowestDateObject);
			console.log('highest date', highestDateObject);

			this.firstDateAvailable = lowestDateObject.date;
			this.lastDateAvailable = highestDateObject.date;
		}, //end afterGetData

		createGeoJsonListState(dataset) {
			const object = dataset.reduce(
				(r, { date, disabled, forecastwindow, parameter, pmgeojsonimagesid, pmorganizationid, variable, organizationname }) => {
					var identifier = pmgeojsonimagesid;
					r[identifier] = {
						identifier: identifier,
						date,
						disabled,
						forecastwindow,
						active: false,
						parameter: parameter,
						pmorganizationid,
						variable,
						title: parameter + ' (' + variable + ')' + ' - ' + forecastwindow,
						organizationname
					};
					return r;
				},
				{}
			);
			console.log('debug geoJson list state', object, Object.values(object));
			this.$store.commit('setGeoJsonLayers', object);
			return Object.values(object);
			//this.loadRasterImages(Object.values(object));
		},

		loadRasterImages(dataset) {
			dataset.forEach((layer) => {
				var layerRasterProperties = VIZ_DEFINITIONS[RASTER_LAYER_PROPERTIES];
				layerRasterProperties.viz.layerName = layer.url;
				ol2map.createWMSLayer(layer.identifier, layerRasterProperties, ol2map.getMapInstance());
			});
		},

		groupByDate(dataset) {
			const object = dataset.reduce((r, { date, ...rest }) => {
				if (!r[date]) r[date] = { date, pollutants: [rest] };
				else r[date].pollutants.push(rest);
				return r;
			}, {});

			const result = Object.values(object);
			return result;
		},

		getGeoJsonImage(index = null, images = null) {
			if (!this.isSuperAdmin || !images) {
				images = Object.values(this.$store.getters.getGeoJsonLayers);
			}
			this.clearGeoJsonImage();
			console.log('debug selected getGeoJsonImage', images, this.dateSelected, index, this.oldDateSelected);

			if (this.oldDateSelected && this.oldDateSelected == this.dateSelected) {
				this.dateSelected = this.oldDateSelected = null;
			} else {
				if (this.dateSelected) {
					this.oldDateSelected = this.dateSelected;
					var dateFixed = new Date(this.dateSelected).addHours(3);
					//console.warn('dateFixed', dateFixed, dateFixed.toISOString().split('T')[0]);
					let selectedElement = images.filter((image) => image.date.split('T')[0] === dateFixed.toISOString().split('T')[0]);
					console.log('debug selectedElement', selectedElement, this.isSuperAdmin);
					if (this.isSuperAdmin) {
						if (selectedElement[0].pollutants.length > 0) {
							console.log(
								'debug selected image superadmin: ',
								selectedElement[0],
								this.groupByPollutant(selectedElement[0].pollutants)
							);
							let selectedImageByPollutant = this.groupByPollutant(selectedElement[0].pollutants);
							this.selectedImage[index] = { date: dateFixed.toISOString().split('T')[0], pollutants: selectedImageByPollutant };
						} else {
							//this.clearSatelliteImage(index);
							this.$puiNotify.info(this.$t('rasterTools.noDataLastSelection'));
						}
					} else {
						if (selectedElement.length > 0) {
							console.log('selected image: ', selectedElement, selectedElement.length, this.groupByPollutant(selectedElement));
							let selectedImageByPollutant = this.groupByPollutant(selectedElement);
							this.selectedImage = { date: dateFixed.toISOString().split('T')[0], pollutants: selectedImageByPollutant };
						} else {
							//this.clearSatelliteImage(index);
							this.$puiNotify.info(this.$t('rasterTools.noDataLastSelection'));
						}
					}
				} else {
					console.log('debug clear sat data');
					//this.clearSatelliteImage(index);
				}
			}
			console.log('getGeoJsonImage', this.selectedImage, this.activeGeoJsonLayer);
		},
		clearGeoJsonImage() {
			this.selectedImage = isSuperAdmin(this.session.profiles[0]) ? [] : null;
			if (this.activeGeoJsonLayer) {
				this.setActive(this.activeGeoJsonLayer, false);
			}
		},
		getGeoJsonTitle(pollutant, parameter) {
			return parameter + ' (' + pollutant.variable + ') - ' + pollutant.forecastwindow;
		},
		groupByOrganization(dataset) {
			console.log('debug group by org', dataset);
			const object = dataset.reduce((r, { organizationname, ...rest }) => {
				if (!r[organizationname]) r[organizationname] = { organizationname, images: [rest] };
				else r[organizationname].images.push(rest);
				return r;
			}, {});

			const result = Object.values(object);
			return result;
		},
		groupByPollutant(dataset) {
			const object = dataset.reduce((r, { parameter, ...rest }) => {
				if (!r[parameter]) r[parameter] = { parameter, images: [rest] };
				else r[parameter].images.push(rest);
				return r;
			}, {});

			const result = Object.values(object);
			return result;
		},
		async getGeoJsonPolygons(image) {
			//Filtro por organización
			let filter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
					{ field: 'pmgeojsonimagesid', op: 'eq', data: image.identifier }
				]
			};

			const body = {
				model: 'pmgeojsonpolygon',
				filter: filter,
				rows: 9999 // Para obtener todos los elementos
			};

			const { data } = await search(body);
			console.log('geojson polygons', data.data, !!data.data[0]);
			if (!data.data[0]) {
				this.$puiNotify.info(this.$t('rasterTools.noDataActiveLayer'));
			} else {
				var polygon = [
					[data.data[0].latmin, data.data[0].lonmin],
					[data.data[0].latmax, data.data[0].lonmin],
					[data.data[0].latmax, data.data[0].lonmax],
					[data.data[0].latmin, data.data[0].lonmax]
				];
				console.log('polygon', polygon);
			}
		},
		getParameterName(acronym) {
			//console.log('parameter name ' + acronym, this.$store.getters.getParameters);
			let parameter = this.$store.getters.getParameters.find((parameter) => parameter.acronym == acronym);
			if (parameter) {
				return parameter.acronym + ' (' + parameter.name + ')';
			}
			return acronym;
		},
		getParameterTooltip(acronym) {
			//console.log('parameter tooltip ' + acronym, this.$store.getters.getParameters);
			let parameter = this.$store.getters.getParameters.find((parameter) => parameter.acronym == acronym);
			if (parameter) {
				return parameter.name + (parameter.description ? ' - ' + parameter.description : '');
			}
			return null;
		},
		allowedDates(val) {
			return this.allowedDatesArray.indexOf(val) !== -1;
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';
.layerslist {
	& .subheader {
		font-size: 10px;
		color: var(--azul-primary-100);

		& .selector {
			color: var(--azul-primary-100);
			font-size: 0.9rem;
			line-height: 1.33;

			&:hover {
				cursor: pointer;
				font-weight: 900;
			}
		}
	}

	& .bottomborder {
		border-bottom: 1px solid var(--moderate);
	}
}
</style>
