<template>
	<div class="layerslist d-flex flex-column pa-0" style="width: 100%">
		<div class="mt-1 subheader" v-if="isExternalLayers">
			<!-- Selection controls: All & Nothing -->
			<span class="selector" @click="changeAll(true)">{{ $t('panels.configpannel.all') }}</span>
			<span class="ml-2 mr-2">|</span>
			<span class="selector" @click="changeAll(false)">{{ $t('panels.configpannel.none') }}</span>
		</div>
		<div v-if="!isSuperAdmin">
			<!-- External Layer List -->
			<v-list dense style="background: transparent !important" class="layer-list py-0">
				<div v-for="(externalLayer, i) in externalLayers" :key="i">
					<layer-list-tooltip
						:value="true"
						:active="isActive(externalLayer.identifier)"
						@update:active="(value) => setActive(externalLayer.identifier, value)"
						:title="externalLayer.rastername"
						:disabledItem="externalLayer.disabledItem"
						:tooltipTitle="getLayerTooltip(externalLayer.description)"
						:noPadding="true"
						border-bottom
					></layer-list-tooltip>
				</div>
			</v-list>
		</div>
		<div v-else>
			<!-- Layer List by Organization-->
			<div v-for="(item, i) in externalLayersByOrg" :key="i">
				<v-list flat dense style="background: transparent" class="pt-0">
					<v-list-group>
						<template #activator>
							<v-list-item-title class="color-black fs-regular fw-bold ml-n2">{{ item.organizationname }}</v-list-item-title>
						</template>

						<div v-for="(externalLayer, i) in item.externalLayerGroup" :key="i">
							<layer-list-tooltip
								:value="true"
								:active="isActive(externalLayer.identifier)"
								@update:active="(value) => setActive(externalLayer.identifier, value)"
								:title="externalLayer.rastername"
								:disabledItem="externalLayer.disabledItem"
								:tooltipTitle="getLayerTooltip(externalLayer.description)"
								:noPadding="true"
								border-bottom
							></layer-list-tooltip>
						</div>
					</v-list-group>
				</v-list>
			</div>
		</div>
	</div>
</template>

<script>
import LayerListTooltip from './subpanels/common/LayerListTooltip';
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';

//import constants from '@/utils/constants';
import { isSuperAdmin } from '@/api/common';

export default {
	name: 'ExternalLayersSection',

	data() {
		return {
			allExternalLayers: [],
			externalLayersByOrg: [],
			profile: '',
			isSuperAdmin: false
		};
	},

	components: {
		//LayerListTitle,
		LayerListTooltip
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		if (this.isSuperAdmin) {
			this.afterGetData();
		}
	},

	computed: {
		isExternalLayers() {
			return Object.keys(this.$store.getters.externalLayers).length > 0;
		},

		externalLayers() {
			return this.$store.getters.externalLayers;
		}
	},

	methods: {
		isActive(externalLayerId) {
			return this.$store.state.mappanel.externalLayers[externalLayerId]['active'];
		},
		changeAll(value) {
			console.log('debug change all', this.externalLayers, Object.values(this.externalLayers), this.$store.state.mappanel.externalLayers);
			Object.values(this.externalLayers).forEach((externalLayer) => this.toggleAll(externalLayer.identifier, value));

			if (!value) {
				this.$puiNotify.info(this.$t('msg.noExternalLayers'));
			}
		},

		toggleAll(externalLayer, value) {
			ol2map.setVisibility(externalLayer, value);
			this.$store.state.mappanel.externalLayers[externalLayer]['active'] = value;
		},

		setActive(externalLayer, active) {
			ol2map.setVisibility(externalLayer, active);
			this.$store.state.mappanel.externalLayers[externalLayer]['active'] = active;
		},
		async afterGetData() {
			this.allExternalLayers = Object.entries(this.externalLayers).map(([name, obj]) => ({ name, ...obj }));
			console.log('-- External Layers retrieved --', this.allExternalLayers, this.externalLayers);
			this.externalLayersByOrg = this.groupByOrganization(this.allExternalLayers);
			console.log('External Layers grouped by organization', this.externalLayersByOrg);
		},

		groupByOrganization(dataset) {
			console.log('DATASET EXTERNAL LAYERS', dataset);
			const object = dataset.reduce((r, { organizationname, ...rest }) => {
				if (!r[organizationname]) r[organizationname] = { organizationname, externalLayerGroup: [rest] };
				else r[organizationname].externalLayerGroup.push(rest);
				return r;
			}, {});

			const result = Object.values(object);
			return result;
		},
		getLayerTooltip(description) {
			console.log('layer tooltip ' + description, this.$store.getters.getParameters);
			if (description && description.trim() != '') {
				return description;
			}
			return null;
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';
.layerslist {
	& .subheader {
		font-size: 10px;
		color: var(--azul-primary-100);

		& .selector {
			color: var(--azul-primary-100);
			font-size: 0.9rem;
			line-height: 1.33;

			&:hover {
				cursor: pointer;
				font-weight: 900;
			}
		}
	}

	& .bottomborder {
		border-bottom: 1px solid var(--moderate);
	}
}
</style>
